import {forwardRef, useCallback, useEffect, useState} from "react";

import API from "../../Api/API";
import {
  ChooseBlock,
  ControlBlock,
  ControlButton,
  ControlButtons,
  PageContainer,
  PageHeading,
  PageWrapper,
  SelectInput,
  SelectLabel,
  StyledDataPicker,
  StyledHeadTr,
  StyledLabel,
  StyledTable,
  StyledTbody,
  StyledTh,
  StyledThead,
  StyledTotalTr,
  StyledTr,
  TableBlock,
  TimeOption
} from './styled';
import DatePicker from "react-datepicker";
import moment from "moment";
import AppsSearchSelect from "../components/apps-search-select/apps-search-select";
import ClientsSearchSelect from "../components/clients-search-select/clients-search-select";
import {Link} from "react-router-dom";
import {utils, writeFile} from 'xlsx';

const Financial = () => {
  const [dateStart, setDateStart] = useState(getOneMonthAgo(new Date()));
  const [dateStop, setDateStop] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const [data, setData] = useState([]);
  const [sortBy, setSortBy] = useState('client');

  const [loading, setLoading] = useState(false);

  const [selectedClient, setSelectedClient] = useState(0);
  const [selectedApp, setSelectedApp] = useState(0);

  const [clientsList, setClientsList] = useState([]);
  const [appsList, setAppsList] = useState([]);

  const ExampleCustomInput = forwardRef(({value, onClick, placeholder}, ref) => (
    <StyledDataPicker onClick={onClick} ref={ref} value={value} placeholder={placeholder} readOnly/>
  ));

  useEffect(() => {
    API.GetClients(0, 999, '').then(({data}) => {
      setClientsList(data);
    });
    API.GetApps(0, 99999, null, '').then(({data}) => {
      setAppsList(data);
    });
  }, [])

  useEffect(() => {
    setData([]);
    if (sortBy === 'client' && dateStart && dateStop) {
      setLoading(true)
      API.analyticsByClients(dateStart, dateStop).then(({data}) => {
        setData(data);
        setLoading(false);
      })
    }
    if (sortBy === 'app' && dateStart && dateStop) {
      setLoading(true);
      API.analyticsByApps(dateStart, dateStop).then(({data}) => {
        setData(data);
        setLoading(false);
      })
    }
    if (sortBy === 'date' && dateStart && dateStop) {
      setLoading(true);
      API.analyticsByDate(dateStart, dateStop, selectedClient, selectedApp).then(({data}) => {
        setData(data);
        setLoading(false);
      })
    }
  }, [sortBy, dateStart, dateStop, selectedClient, selectedApp])

  function getOneMonthAgo(date) {
    return moment(date.setMonth(date.getMonth() - 1)).format("YYYY-MM-DD");
  }

  const handleChangeSelectedApp = (id) => {
    setSelectedApp(id)
  }

  const handleChangeSelectedClient = (id) => {
    setSelectedClient(id)
  }

  const handleSelectSort = (event) => {
    setSortBy(event.target.value);
  }

  const exportFile = useCallback(() => {
    const worksheetData = data.map(obj => {
      const newObj = {...obj};
      newObj['Costs'] = newObj['Spent'];
      delete newObj['Spent'];

      newObj['Revenue'] = newObj['Profit'];
      delete newObj['Profit'];

      return newObj
    })

    const ws = utils.json_to_sheet(worksheetData);
    const wb = utils.book_new();

    utils.book_append_sheet(wb, ws, `Data`);

    if (sortBy === 'client') {
      writeFile(wb, `financial_report_by_clients_${dateStart}_${dateStop}.xlsx`)
    } else if (sortBy === 'app') {
      writeFile(wb, `financial_report_by_apps_${dateStart}_${dateStop}.xlsx`)
    } else {
      const clientName = clientsList.find((item) => selectedClient === item.id);
      const appName = appsList.find((item) => selectedApp === item.id);
      writeFile(wb, `financial_report_by_date_${dateStart}_${dateStop}_${clientName ? clientName.name.replace(/[\s_]/g, "-") : ''}${appName ? appName.name.replace(/[\s_]/g, "-") : ''}.xlsx`)
    }

  }, [data])

  return (
    <>
      <PageContainer>
        <PageWrapper>
          <PageHeading>Financial</PageHeading>
          <ControlBlock>
            <SelectLabel>
              Sort by
              <SelectInput onChange={handleSelectSort}>
                <option value={'client'}>Client</option>
                <option value={'app'}>App</option>
                <option value={'date'}>Date</option>
              </SelectInput>
            </SelectLabel>
            <StyledLabel>
              <TimeOption>
                <DatePicker
                  onChange={(date) => {
                    setDateStart(moment(date).format("YYYY-MM-DD"));
                  }}
                  selected={new Date(dateStart)}
                  customInput={<ExampleCustomInput/>}
                  placeholderText={'Start date'}
                  closeOnScroll={true}
                  dateFormat="yyyy-MM-dd"
                  locale="en"
                  dayClassName={(date) =>
                    date.getDay() === 1 ? "monday" : undefined
                  }
                />
              </TimeOption>
              <TimeOption>
                <DatePicker
                  onChange={(date) => {
                    setDateStop(moment(date).format("YYYY-MM-DD"));
                  }}
                  selected={new Date(dateStop)}
                  customInput={<ExampleCustomInput/>}
                  placeholderText={'Stop date'}
                  closeOnScroll={true}
                  dateFormat="yyyy-MM-dd"
                  locale="en"
                  dayClassName={(date) =>
                    date.getDay() === 1 ? "monday" : undefined
                  }
                />
              </TimeOption>
            </StyledLabel>
            <ControlButtons>
              <ControlButton onClick={exportFile}>Get CSV</ControlButton>
              <Link to={"/Clients"}>
                <ControlButton>Back To Clients</ControlButton>
              </Link>
            </ControlButtons>
            {sortBy === 'date' ?
              <ChooseBlock>
                <StyledLabel icon={'search'}>
                  <AppsSearchSelect client={selectedClient} handleSelectApp={handleChangeSelectedApp} size={'small'}/>
                </StyledLabel>
                <StyledLabel icon={'user'}>
                  <ClientsSearchSelect handleSelectClient={handleChangeSelectedClient} size={'small'}/>
                </StyledLabel>
              </ChooseBlock>
              : ''
            }
          </ControlBlock>
          <TableBlock>
            {sortBy === 'client' ?
              <StyledTable>
                <StyledThead>
                  <StyledHeadTr>
                    <StyledTh>Client ID</StyledTh>
                    <StyledTh>Client</StyledTh>
                    <StyledTh>Costs</StyledTh>
                    <StyledTh>Revenue</StyledTh>
                    <StyledTh>Margin</StyledTh>
                  </StyledHeadTr>
                </StyledThead>
                <StyledTbody>
                  {data.length !== 0 &&
                    data.map(client =>
                      <StyledTr key={client.id}>
                        <StyledTh>{client.id}</StyledTh>
                        <StyledTh>{client.Client}</StyledTh>
                        <StyledTh>{client.Spent.toFixed(2)}</StyledTh>
                        <StyledTh>{client.Profit.toFixed(2)}</StyledTh>
                        <StyledTh>{client.Margin.toFixed(2)}</StyledTh>
                      </StyledTr>
                    )
                  }
                  <StyledTotalTr>
                    <StyledTh>Total</StyledTh>
                    <StyledTh> </StyledTh>
                    <StyledTh>{data.reduce((accumulator, obj) => accumulator + obj.Spent, 0).toFixed(2)}</StyledTh>
                    <StyledTh>{data.reduce((accumulator, obj) => accumulator + obj.Profit, 0).toFixed(2)}</StyledTh>
                    <StyledTh>{data.reduce((accumulator, obj) => accumulator + obj.Margin, 0).toFixed(2)}</StyledTh>
                  </StyledTotalTr>
                </StyledTbody>
              </StyledTable>
              : sortBy === 'app' ?
                <StyledTable>
                  <StyledThead>
                    <StyledHeadTr>
                      <StyledTh>App ID</StyledTh>
                      <StyledTh>App</StyledTh>
                      <StyledTh>Client</StyledTh>
                      <StyledTh>Costs</StyledTh>
                      <StyledTh>Revenue</StyledTh>
                      <StyledTh>Margin</StyledTh>
                    </StyledHeadTr>
                  </StyledThead>
                  <StyledTbody>
                    {data.length !== 0 &&
                      data.map(app =>
                        <StyledTr key={app.id}>
                          <StyledTh>{app.id}</StyledTh>
                          <StyledTh>{app.App}</StyledTh>
                          <StyledTh>{app.Client}</StyledTh>
                          <StyledTh>{app.Spent.toFixed(2)}</StyledTh>
                          <StyledTh>{app.Profit.toFixed(2)}</StyledTh>
                          <StyledTh>{app.Margin.toFixed(2)}</StyledTh>
                        </StyledTr>
                      )
                    }
                    <StyledTotalTr>
                      <StyledTh>Total</StyledTh>
                      <StyledTh> </StyledTh>
                      <StyledTh> </StyledTh>
                      <StyledTh>{data.reduce((accumulator, obj) => accumulator + obj.Spent, 0).toFixed(2)}</StyledTh>
                      <StyledTh>{data.reduce((accumulator, obj) => accumulator + obj.Profit, 0).toFixed(2)}</StyledTh>
                      <StyledTh>{data.reduce((accumulator, obj) => accumulator + obj.Margin, 0).toFixed(2)}</StyledTh>
                    </StyledTotalTr>
                  </StyledTbody>
                </StyledTable>
                :
                <StyledTable>
                  <StyledThead>
                    <StyledHeadTr>
                      <StyledTh>Date</StyledTh>
                      <StyledTh>Costs</StyledTh>
                      <StyledTh>Revenue</StyledTh>
                      <StyledTh>Margin</StyledTh>
                    </StyledHeadTr>
                  </StyledThead>
                  <StyledTbody>
                    {data.length !== 0 &&
                      data.map((date, index) =>
                        <StyledTr key={index}>
                          <StyledTh>{date.Date}</StyledTh>
                          <StyledTh>{date.Spent.toFixed(2)}</StyledTh>
                          <StyledTh>{date.Profit.toFixed(2)}</StyledTh>
                          <StyledTh>{date.Margin.toFixed(2)}</StyledTh>
                        </StyledTr>
                      )
                    }
                    <StyledTotalTr>
                      <StyledTh>Total</StyledTh>
                      <StyledTh>{data.reduce((accumulator, obj) => accumulator + obj.Spent, 0).toFixed(2)}</StyledTh>
                      <StyledTh>{data.reduce((accumulator, obj) => accumulator + obj.Profit, 0).toFixed(2)}</StyledTh>
                      <StyledTh>{data.reduce((accumulator, obj) => accumulator + obj.Margin, 0).toFixed(2)}</StyledTh>
                    </StyledTotalTr>
                  </StyledTbody>
                </StyledTable>
            }
          </TableBlock>
        </PageWrapper>
      </PageContainer>
    </>
  )
}
export default Financial;