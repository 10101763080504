import * as React from 'react';
import {useEffect, useState} from 'react';
import {Dialog, DialogContent, DialogTitle} from '@mui/material';
import useDebounce from "../utils/use-debounce"
import API from "../../Api/API";
import NewAppModal from "./new-app-form/new-app-modal"
import AppCard from './app-card/app-card';
import AddNewAppBtn from './add-app-card/add-app-card-btn';
import {
  InputBlock,
  MyApps,
  MyAppsHeading,
  SearchInput,
  SearchInputLabel,
  SelectInput,
  SelectInputLabel,
  StyledAppList
} from './styled';
import {Loader} from 'rsuite';
import {throwToastSuccess} from "../utils";
import ClientsSearchSelect from "../components/clients-search-select/clients-search-select";

let isEnded = false;

const Apps = () => {
  const [AppList, setAppList] = useState([]);
  const [clients, setClients] = useState([]);
  const [open, setOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [fetching, setFetching] = useState(true);
  const [appSearch, setAppSearch] = useState('');
  const [client, setClient] = useState(0);
  const [loading, setLoading] = useState(true);

  const debouncedSearch = useDebounce(appSearch, 500)

  useEffect(() => {
    if (fetching) {
      API.GetApps(offset, 20, client, appSearch)
        .then((data) => {
          setAppList([...AppList, ...data.data]);
          setOffset(prevState => prevState + 20);
          if (data.data.length !== 20) {
            isEnded = true
          }
        })
        .finally(() => {
          setLoading(false);
          setFetching(false);
        })
    }

  }, [fetching]);

  useEffect(() => {
    if (debouncedSearch !== undefined || client !== 0) {
      isEnded = false;
      setAppList([]);
      setOffset(0)
      setFetching(true)
    }
  }, [debouncedSearch, client]);

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
    API.GetClients(0, 999, '').then((data) => {
      setClients(data.data)
    })
    return function () {
      document.removeEventListener('scroll', scrollHandler);
    }
  }, [])

  const scrollHandler = (e) => {
    if ((e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100) && !isEnded) {
      setFetching(true);
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseAddAppModal = () => {
    setOpen(false);
  };

  const handleChangeSelectedClient = (id) => {
    setClient(id)
  }

  const handleSearch = (e) => {
    setAppSearch(e.target.value);
  }

  const handleDeleteApp = (id) => {
    setLoading(true)
    API.appDelete({"id": id}).then((result) => {
      setAppList([]);
      setOffset(0);
      setFetching(true);
      setLoading(false)
    })
  }

  const handleAddNewApp = () => {
    setLoading(true);
    setOffset(0);
    setAppList([]);
    setFetching(true);
    throwToastSuccess('Application added successfully')
    handleCloseAddAppModal();
  };

  return (
    <MyApps>
      <MyAppsHeading>My apps</MyAppsHeading>
      <InputBlock>
        <SearchInputLabel>
          <SearchInput placeholder='App search' onChange={handleSearch}/>
        </SearchInputLabel>
        <SelectInputLabel>
          <ClientsSearchSelect handleSelectClient={handleChangeSelectedClient} size={'md'}/>
        </SelectInputLabel>
      </InputBlock>
      <StyledAppList>
        <AddNewAppBtn onClick={handleClickOpen}></AddNewAppBtn>
        {AppList && AppList?.map((card) => {
          return (
            <AppCard card={card} key={card.id} handle={handleDeleteApp}/>
          )
        })}

        {loading ?
          <Loader backdrop size="lg" content="loading..." vertical/>
          : ''}
      </StyledAppList>

      <Dialog
        onClose={handleCloseAddAppModal}
        aria-labelledby="customized-dialog-title"
        scroll='paper'
        PaperProps={{style: {overflow: "visible"}}}
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCloseAddAppModal}>
          Add App
        </DialogTitle>
        <DialogContent style={{overflow: "visible"}} dividers>
          <NewAppModal handleAddNewApp={handleAddNewApp}/>
        </DialogContent>
      </Dialog>

    </MyApps>
  )
}
export default Apps;