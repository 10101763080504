import styled from 'styled-components';
import searchImg from '../../Images/icons/search-icon.svg';
import userImg from '../../Images/icons/user-icon.svg';
import clockImg from '../../Images/icons/clock-icon.svg';
import appStoreIcon from '../../Images/AppStore.png'
import playMarketIcon from '../../Images/googlePlay.png'
import settingsIcon from '../../Images/icons/settings-btn.svg'
import settingsIconHover from '../../Images/icons/settings-btn-hover.svg'

const greenStatus = '#73D27C';
const redStatus = '#D27373';
const blueStatus = '#69C0F1';
const orangeStatus = '#FF9417';
const blackStatus = '#000000';
const pinkStatus = '#E84FE2';

export const StyledPage = styled.div`
  font-family: 'Urbanist', sans-serif;
  display: flex;
  flex-direction: column;
  padding: 35px 25px;
`;

export const StyledHeading = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 100%;
  color: #3E3E3E;
  margin: 0;
  margin-bottom: 42px;
`;

export const FilterRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

export const AddButton = styled.button`
  background-color: #3e3e3e;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px 0;
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  width: 200px;
`;

export const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  &::before {
    content: '';
    display: block;
    background-image: url(${(props) => props.icon === 'search' ? searchImg : props.icon === 'user' ? userImg : clockImg});
    background-repeat: no-repeat;
    background-size: contain;
    width: 22px;
    height: 22px;
  }
`;

export const StyledSelect = styled.select`
  background-color: inherit;
  color: #3E3E3E;
  font-family: 'Urbanist', sans-serif;
  font-weight: 600;
  font-size: 14px;
  width: 465px;
  min-height: 30px;
  padding: 8px 15px;
  box-sizing: border-box;
  border: 1px solid rgba(62, 62, 62, 0.5);
  border-radius: 5px;
  max-width: 200px;
  cursor: pointer;
`;

export const StyledDataPicker = styled.input`
  background-color: inherit;
  color: #3E3E3E;
  font-family: 'Urbanist', sans-serif;
  font-weight: 600;
  font-size: 14px;
  width: 465px;
  min-height: 30px;
  padding: 8px 15px;
  box-sizing: border-box;
  border: 1px solid rgba(62, 62, 62, 0.5);
  border-radius: 5px;
  max-width: 150px;
  cursor: pointer;
`;

export const FilterButtons = styled.div`
  display: flex;
  gap: 1px;
`;

export const FilterButton = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  padding: 13px 0;
  border: 0;
  border-left: 1px solid #EAEAEA;
  background-color: ${(props) => props.active ? '#ffffff' : '#3E3E3E'};
  color: ${(props) => props.active ? '#3E3E3E' : '#ffffff'};
  width: 100px;
  cursor: pointer;
`;

export const TimeOption = styled.div`
  position: relative;
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  color: #3E3E3E;
  max-width: 150px;
`;

export const StatusItem = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 900;
  width: 100%;
  justify-content: center;
  color: ${blackStatus};

  &[data-color='1'] {
    color: ${blueStatus}
  }

  &[data-color='2'] {
    color: ${greenStatus}
  }

  &[data-color='3'] {
    color: ${orangeStatus}
  }

  &[data-color='4'] {
    color: ${redStatus}
  }

  &[data-color='5'] {
    color: ${blackStatus}
  }

  &[data-color='6'] {
    color: ${blackStatus}
  }

  &[data-color='7'] {
    color: ${pinkStatus}
  }
`;


export const PopoverContent = styled.div`
  width: 200px;
  height: 120px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const PopoverButton = styled.button`
  min-width: 50px;
  padding: 5px;
  color: #ffffff;
  background-color: #3E3E3E;
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  border: 0;
  border-radius: 5px;
`;

export const StoreIcon = styled.div`
  width: 12px;
  background-image: url(${props => props.store === 'google' ? playMarketIcon : appStoreIcon});
  background-size: contain;
  background-repeat: no-repeat;
  height: 12px;
`;

export const ClearBtn = styled.button`
  font-size: 14px;
  width: 14px;
  height: 14px;
  border: 0;
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  padding: 8px;
  box-sizing: content-box;
  line-height: 100%;
  background-color: #ffffff;
`;

export const TableBlock = styled.div`
  margin-top: 40px;
  overflow: hidden;
  width: 100%;
`;

export const StyledTable = styled.table`
  width: inherit;
  position: relative;
`;

export const StyledThead = styled.thead`
  background: rgba(222, 222, 222, 0.82);
  border-top: 1px solid rgba(115, 127, 210, 0.25);

  position: sticky;
  top: 0;
`;

export const StyledHeadTr = styled.tr`
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
`;

export const StyledTbody = styled.tbody`
  width: 100%;
`;

export const StyledTr = styled.tr`
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: #3E3E3E;
  border-bottom: 1px solid rgba(115, 127, 210, 0.25);
`;

export const StyledTh = styled.th`
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 12px;
`;

export const StyledTd = styled.td`
  font-family: 'Urbanist', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: ${props => props.appName ? '180%' : '100%'};
  padding: 12px;
  white-space: nowrap;
  text-align: center;

  div {
    gap: 3px;
    display: flex;
    justify-content: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
  }
`;

export const SettingsButton = styled.button`
  width: 18px;
  height: 18px;
  border: 0;
  background-color: transparent;
  background-image: url(${settingsIcon});
  background-repeat: no-repeat;
  background-size: contain;

  &:hover {
    background-image: url(${settingsIconHover});
  }
`;