import { forwardRef, useEffect, useState} from 'react';
import {
  DateFilterBlock, DateInput, FilterBlock,
  LogsBlock, StyledHeadTr, StyledTable,
  StyledThead, TableBlock, StyledTh,
  StyledTbody, StyledTr, StyledDataPicker, DateFilterLabel, CloseButton, ClearBtn
} from './styled';
import DatePicker from "react-datepicker"
import API from '../../../Api/API';
import moment from 'moment/moment';

import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BalanceLogs = ({type, logId, close}) => {
  const [logsList, setLogsList] = useState([]);
  const [dateStart, setDateStart] = useState();
  const [dateStop, setDateStop] = useState();

  useEffect(() => {
    API.GetLogs({
      "typeLog": 'money',
      "type": type,
      "id": logId,
      "date_start": dateStart,
      "date_stop": moment(getNextDay(dateStop)).format('YYYY-MM-DD')
    }).then(data => {
      setLogsList(data.data)
    })
  }, [])

  useEffect(() => {
      API.GetLogs({
        "typeLog": 'money',
        "type": type,
        "id": logId,
        "date_start": dateStart,
        "date_stop": moment(getNextDay(dateStop)).format('YYYY-MM-DD')
      }).then(data => {
        setLogsList(data.data)
      })
  }, [dateStart, dateStop])

  const ExampleCustomInput = forwardRef(({value, onClick, placeholder}, ref) => (
    <StyledDataPicker onClick={onClick} ref={ref} value={value} placeholder={placeholder} readOnly />
  ));

  const getNextDay = (date) => {
    const nextDate = new Date(date);
    nextDate.setDate(new Date(date).getDate() + 1);
    return nextDate;
  }

  const handleClearStartDate = () => {
    setDateStart(0)
  }

  const handleClearEndDate = () => {
    setDateStop(0)
  }

  const handleCopyClick = (e) => {
    navigator.clipboard.writeText(e.target.title)
      .then(() => {
        toast("Текст скопирован в буфер обмена");
      })
      .catch((error) => {
        console.error('Не удалось скопировать текст: ', error);
      });
  };

  return (
    <LogsBlock>
      <ToastContainer autoClose={1000} pauseOnFocusLoss={false}/>
      <FilterBlock>
        <DateFilterLabel>
          <DateFilterBlock>
            <DateInput>
              <DatePicker
                onChange={(date) => {
                  setDateStart(date);
                }}
                selected={dateStart}
                customInput={<ExampleCustomInput />}
                placeholderText={'Start date'}
                closeOnScroll={true}
                dateFormat="yyyy-MM-dd"
                maxDate={new Date()}
              />
              {dateStart ? <ClearBtn onClick={handleClearStartDate}>✖</ClearBtn> : ''}
            </DateInput>
            <DateInput>
              <DatePicker
                onChange={(date) => {
                  setDateStop(date);
                }}
                selected={dateStop}
                customInput={<ExampleCustomInput />}
                placeholderText={'Stop date'}
                closeOnScroll={true}
                dateFormat="yyyy-MM-dd"
                maxDate={getNextDay(new Date())}
              />
              {dateStop ? <ClearBtn onClick={handleClearEndDate}>✖</ClearBtn> : ''}
            </DateInput>
          </DateFilterBlock>
        </DateFilterLabel>
      </FilterBlock>
      <TableBlock>
        <StyledTable>
          <StyledThead>
            <StyledHeadTr>
              <StyledTh>Date</StyledTh>
              <StyledTh>Type</StyledTh>
              <StyledTh>Amount</StyledTh>
              <StyledTh>Before</StyledTh>
              <StyledTh>After</StyledTh>
              <StyledTh>User id</StyledTh>
              {type === 'clients' && <StyledTh>Comment</StyledTh>}
            </StyledHeadTr>
          </StyledThead>
          <StyledTbody>
            {logsList.length !== 0 &&
              logsList.map(log =>
                <StyledTr key={log.id}>
                  <StyledTh date>{moment(new Date(log.createdAt)).format('YYYY-MM-DD, HH:mm')}</StyledTh>
                  <StyledTh>{log.value}</StyledTh>
                  <StyledTh color={Number(log.amount) > 0 ? 'green' : Number(log.amount) < 0 ? 'red' : null}>{Number(log.amount).toFixed(2)}</StyledTh>
                  <StyledTh>{Number(log.before).toFixed(2)}</StyledTh>
                  <StyledTh>{Number(log.after).toFixed(2)}</StyledTh>
                  <StyledTh>{log.auth_user_id}</StyledTh>
                  {type === 'clients' && <StyledTh comment title={log.comment} onClick={handleCopyClick}>{log?.comment?.slice(0, 30)}</StyledTh>}
                </StyledTr>
              )}

          </StyledTbody>
        </StyledTable>
      </TableBlock>
      <CloseButton onClick={() => close()}>Close</CloseButton>
    </LogsBlock>
  );
}

export default BalanceLogs;
